<script>
import _ from 'lodash';
export default {
  data() {
    return {
      employer: this.$store.getters['employerModule/getUser'],
    };
  },
  methods: {
    updatePreferences: _.debounce(function () {
      try {
        this.$store.dispatch('employerModule/patch', this.employer);
        this.$vs.notify({
          title: 'Success!',
          color: 'success',
          text: `Your email preferences have been updated`,
          time: 8000,
        });
      } catch (error) {
        this.$emit('displayError', error);
      }
    }, 300),
  },
};
</script>

<template>
  <div>
    <h2 id="profile-step-1" class="pt-2">Email Preferences</h2>
    <vs-divider></vs-divider>
    <h5 class="mb-5">Activity Notifications</h5>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full lg:mb-5 mb-5">
        <vs-checkbox v-model="employer.emailPreferences.newMessages" class="mb-2"> <p class="font-semibold">New messages</p></vs-checkbox>
        <p class="text-sm">Receive an email when an applicant sends you a message</p>
      </div>
      <div class="vx-col sm:w-1/2 w-full lg:mb-5 mb-5">
        <vs-checkbox v-model="employer.emailPreferences.newApplicants" class="mb-2">
          <p class="font-semibold">New applicants</p></vs-checkbox
        >
        <p class="text-sm">Receive an email when a guide applies to one of your jobs</p>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full lg:mb-5 mb-5">
        <vs-checkbox v-model="employer.emailPreferences.acceptedInvitations" class="mb-2">
          <p class="font-semibold">Accepted invitations</p></vs-checkbox
        >
        <p class="text-sm">Receive an email when a guide accepts an invite to apply to one of your job posts</p>
      </div>
      <div class="vx-col sm:w-1/2 w-full lg:mb-5 mb-5">
        <vs-checkbox v-model="employer.emailPreferences.jobExpiring" class="mb-2"> <p class="font-semibold">Job expiring</p></vs-checkbox>
        <p class="text-sm">Receive an email when a job post is about to expire</p>
      </div>
    </div>
    <h5 class="my-5">Marketing Notifications</h5>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full lg:mb-5 mb-5">
        <vs-checkbox v-model="employer.emailPreferences.ragNewsletter" class="mb-2">
          <p class="font-semibold">Recruit a Guide newsletter</p></vs-checkbox
        >
        <p class="text-sm">Upcoming events, community content, videos, podcasts, and exciting announcements</p>
      </div>
      <div class="vx-col sm:w-1/2 w-full lg:mb-5 mb-5">
        <vs-checkbox v-model="employer.emailPreferences.productUpdates" class="mb-2">
          <p class="font-semibold">Product updates</p></vs-checkbox
        >
        <p class="text-sm">Information about the latest Recruit a Guide product updates and features</p>
      </div>
    </div>
    <vs-button class="mt-3 w-full md:w-1/3 xxl:w-1/5" color="#ADCA63" @click="updatePreferences()"> Update preferences </vs-button>
  </div>
</template>
