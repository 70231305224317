<script>
import { getAuth, updatePassword } from 'firebase/auth';

export default {
  props: {
    employer: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      //   employer: this.$store.getters['employerModule/getUser'],
      updatePasswordPopup: false,
      successPopup: false,
      userPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
  },
  computed: {
    validateChangePassword() {
      return !this.errors.any() && this.userPassword !== '' && this.newPassword !== '' && this.confirmPassword !== '';
    },
  },
  methods: {
    async forgotPassword() {
      this.updatePasswordPopup = false;
      const payload = {
        email: this.employer.email,
        notify: this.$vs.notify,
      };
      this.$store.dispatch('auth/forgotPassword', payload);
    },
    resetPopup() {
      this.updatePasswordPopup = false;
      this.userPassword = '';
      this.newPassword = '';
      this.confirmPassword = '';
      this.errors.clear();
    },
    initUpdatePassword() {
      this.updatePasswordPopup = true;
    },
    async updateUserPassword() {
      if (!this.validateChangePassword) return;
      this.$vs.loading({ type: 'corners' });
      const auth = getAuth();
      try {
        await this.$store.dispatch('auth/reauthenticate', this.userPassword);
        await updatePassword(auth.currentUser, this.newPassword);
        this.$vs.loading.close();
        this.successPopup = true;
        this.resetPopup();
      } catch (error) {
        console.log(error);
        this.$vs.loading.close();
        this.userPassword = '';
        this.$emit('displayError', error.message);
      }
    },
  },
};
</script>

<template>
  <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
    <vs-input disabled icon="lock" icon-no-border value="1234567" class="w-full" type="password" label="Current password" />
    <vs-button class="w-full md:w-2/3 xxl:w-1/3 mt-6" color="#ADCA63" @click="initUpdatePassword">Change password</vs-button>

    <vs-popup title="Change your password" :active.sync="updatePasswordPopup" @close="resetPopup()">
      <vs-input
        v-model.trim="userPassword"
        v-validate="'required|min:6|max:20'"
        label="Current password"
        data-vv-validate-on="blur"
        :danger="!!errors.first('current password')"
        :danger-text="errors.first('current password')"
        type="password"
        name="current password"
        class="w-full mb-3"
        placeholder="Input your current password..."
      ></vs-input>
      <vs-input
        ref="password"
        v-model="newPassword"
        v-validate="'required|min:6|max:20'"
        type="password"
        label="New password"
        data-vv-validate-on="blur"
        :danger="!!errors.first('password')"
        :danger-text="errors.first('password')"
        name="password"
        placeholder="Input new password"
        class="w-full mb-3"
      />

      <vs-input
        v-model="confirmPassword"
        v-validate="'required|min:6|max:20|confirmed:password'"
        type="password"
        data-vv-validate-on="blur"
        label="Confirm password"
        :danger="!!errors.first('confirm_password')"
        :danger-text="errors.first('confirm_password')"
        data-vv-as="password"
        name="confirm_password"
        placeholder="Confirm new password"
        class="w-full mb-8"
      />

      <div class="vx-row mr-0 ml-0 mt-3">
        <vs-button :disabled="!validateChangePassword" color="primary" class="vx-col lg:w-1/4 ml-0" @click="updateUserPassword()">
          Update password</vs-button
        >
        <vs-button color="primary" type="flat" class="vx-col px-2 ml-0 sm:px-8 sm:ml-5" @click="forgotPassword">
          I forgot my password</vs-button
        >
      </div>
    </vs-popup>
    <vs-popup title="Password changed!" :active.sync="successPopup">
      <p> Your password has been successfully updated. You can use your new password next time you login. </p>
      <vs-button class="mt-5" @click="successPopup = false"> Okay </vs-button>
    </vs-popup>
  </div>
</template>
