<script>
import { getAuth, updateEmail } from 'firebase/auth';

import firebase from 'firebase/compat/app';
export default {
  props: {
    employer: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      updateEmailPopup: false,
      successPopup: false,
      newEmail: '',
      userPassword: '',
    };
  },
  computed: {
    validateChangeEmail() {
      return !this.errors.any() && this.newEmail !== '' && this.userPassword !== '';
    },
  },
  methods: {
    resetPopup() {
      this.updateEmailPopup = false;
      this.newEmail = '';
      this.userPassword = '';
      this.errors.clear();
    },

    initUpdateEmail() {
      this.updateEmailPopup = true;
    },
    async updateUserEmail() {
      if (!this.validateChangeEmail) return;
      this.$vs.loading({ type: 'corners' });
      const auth = getAuth();
      try {
        await this.$store.dispatch('auth/reauthenticate', this.userPassword);
        await updateEmail(auth.currentUser, this.newEmail);
        await firebase.firestore().collection('employers').doc(this.employer.employerId).update({ email: this.newEmail });
        // await sendEmailVerification(auth.currentUser);
        await this.$store.dispatch('auth/generateActionLink', { email: this.newEmail, linkType: 'verify-email' });
        this.$vs.loading.close();
        this.resetPopup();
        this.successPopup = true;
      } catch (error) {
        this.$vs.loading.close();
        this.$emit('displayError', error.message);
        this.resetPopup();
      }
    },
  },
};
</script>

<template>
  <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
    <vs-input :value="employer.email" disabled icon="email" icon-no-border class="w-full" label="Current email" />
    <vs-button class="w-full md:w-2/3 xxl:w-1/3 mt-6" color="#ADCA63" @click="initUpdateEmail">Change email</vs-button>

    <vs-popup title="Change your email" :active.sync="updateEmailPopup" @close="resetPopup()">
      <vs-input :value="employer.email" label="Current email" disabled class="w-full mb-3" placeholder="Input your password..."></vs-input>

      <vs-input
        v-model="newEmail"
        v-validate="'required|email'"
        data-vv-validate-on="blur"
        label="New email"
        :danger="!!errors.first('new email')"
        :danger-text="errors.first('new email')"
        name="new email"
        class="w-full mb-3"
        placeholder="Input your new email"
      ></vs-input>

      <vs-input
        v-model="userPassword"
        v-validate="'required|min:6|max:20'"
        label="Password"
        type="password"
        name="password"
        :danger="!!errors.first('password')"
        :danger-text="errors.first('password')"
        data-vv-validate-on="blur"
        class="w-full mb-8"
        placeholder="Input your password..."
      ></vs-input>

      <div class="vx-row mr-0 ml-0 mt-3">
        <vs-button :disabled="!validateChangeEmail" color="primary" class="vx-col lg:w-1/4 ml-0" @click="updateUserEmail()">
          Update email</vs-button
        >
      </div>
    </vs-popup>
    <vs-popup title="Please verify your new email" :active.sync="successPopup">
      <p> A verification link has been sent to your new email address. Please follow that link to verify your email. </p>
      <vs-button class="mt-5" @click="successPopup = false"> Okay </vs-button>
    </vs-popup>
  </div>
</template>
